@charset "utf-8"; 

/* *******************************************************
    filename : header.css
    description : header layout CSS
******************************************************** */

// import
@import '../../media/css/mixin.scss';

/* ==================== header stylings ====================== */
.mob-hide{
  display: block;
}
.home__header{
    position: absolute;
    width: 100%;
    top: 0;
    left:50%;
    transform: translate(-50%, 0);
    background-color: transparent;
    z-index: 1;
    .container{
        @include dspCollection(flex,space-between,center);
        max-width: 1140px; 
        width: $size100; 
        height: 100px;
        margin: auto; 
        padding: 24px 10px 24px;
        @include boxsizing;

        .header--container__leftWrap{
          @include dspCollection(flex, center, center);
          gap: 100px;
        }
    }
}
.header__logo__link{
    &--img{display: inline-block;width: 30px;}   
    &--txt{
        padding-left: 10px;
        font-family: var(--font1);
        @include font(normal,bold,18px,#fff,24px);
        letter-spacing: 0.75px;
    }     
}
/* nav */
.home__nav{
    display: flex;
    .nav__item{
        position: relative;
        text-align: center;
        .menuButton{
            padding: 15px 40px;
            font-family: var(--font0);
            @include font(normal,400,18px,#999,21px);
            letter-spacing: -0.41px;
            position: relative;
            cursor: pointer;
            &:hover{
                mask-image: linear-gradient(-75deg, rgba(0,0,0,.6) 30%, #000 50%, rgba(0,0,0,.6) 70%);
                mask-size: 200%;
                -webkit-mask-image: linear-gradient(-75deg, rgba(0,0,0,.6) 30%, #000 50%, rgba(0,0,0,.6) 70%);
                -webkit-mask-size: 200%;
                @include animation(shine 2s infinite)
            }
            .ico__arrow{padding-left: 8px;}
            .coming-soon{
              @include font(normal,400,12px,#999,14px);
              text-align: center;
              position: absolute;        
              left: 50%;
              bottom: -5px;
              transform: translate(-50%, 0);
            }
          }
          .openMenuButton{
            color: #fff !important;
          }
        .menu{
            display: none;
            position: absolute;
            width: 100%;
            padding: 15px;
            border-radius: 10px;
            background-color: #202024;
            box-shadow: 1px 1px 15px #303030;
            @include boxsizing;
            &.active{display: block}
            .item + .item{padding-top: 5px;}
            .menu__item{
                display: block;
                font-family: var(--font2);
                @include font(normal,normal,15px,#fff,24px);
                text-align: left;
                letter-spacing: -0.41px;
                transition: color .3s;
                &:hover{
                    mask-image: linear-gradient(-75deg, rgba(0,0,0,.6) 30%, #000 50%, rgba(0,0,0,.6) 70%);
                    mask-size: 200%;
                    -webkit-mask-image: linear-gradient(-75deg, rgba(0,0,0,.6) 30%, #000 50%, rgba(0,0,0,.6) 70%);
                    -webkit-mask-size: 200%;
                    @include animation(shine 2s infinite);
                    color: #00F1FF;
                }
            }
        }
        &:hover{
            .menu{display: block}
        }
    }
}


.nav__submenu {display: none;}
  
.nav__menu-item:hover {
    .nav__submenu {display: block;}
}
  
$color-blue: #00aeef;
$color-blue-dark: #0d2035;
$submenu-width: 180px;

.menu-container {text-align: center;}
  
nav {
    ul {
        list-style: none;
        padding-left: 0;
        margin-top: 0;
        margin-bottom: 0;
    }
}
  
.nav {
    display: inline-block;
    margin: 2rem auto 0;
    background: $color-blue-dark;
    color: #fff;
    text-align: left;
    a {
        display: block;
        padding: 0 16px;
        line-height: inherit;
        cursor: pointer;
    }
}
    
.nav__menu {
    line-height: 45px;
    font-weight: 700;
    text-transform: uppercase;
}
    
.nav__menu-item {
    display: inline-block;
    position: relative;
  
    &:hover {
      background-color: $color-blue;
      .nav__submenu {display: block;}
    }
}
      
.nav__submenu {
    font-weight: 300;
    text-transform: none;
    display: none;
    position: absolute;
    width: $submenu-width;
    background-color: $color-blue;
}
      
.nav__submenu-item {
    &:hover {background: rgba(#000, 0.1);}
}

.menu__btn__box{
    @include flexCenter;
    
    &--snsWrap{
      @include dspCollection(flex, center, center);
      gap: 25px;
      li{
        cursor: pointer;
        img{
          width: 25px;
        }
      }
    }
    &--walletBtn{
      width: fit-content;
      height: fit-content;
      @include font(normal,normal,15px,#fff,24px);
      margin-left: 20px;
      border: 1px solid #656565;
      min-width: 140px;
      padding: 0.5rem 1rem;
      border-radius: 12px;
      background-color:  rgb(28 28 28);
    }
    &--walletBtn-onMob{
      display: none;
      margin-top: 20px;
      width: 100%;
      height: fit-content;
      @include font(normal,normal,15px,#fff,24px);
      border: 1px solid #656565;
      min-width: 140px;
      padding: 0.5rem 1rem;
      border-radius: 12px;
      background-color:  rgb(28 28 28);
    }
}

.mob__menu__box{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    min-height: 100vh;
    padding: 118px 33px 32px 33px;
    background-color: #202024;
    z-index: 12;
    @include boxsizing;
    .mob__menu{
        &--link{
            font-family: var(--font1);
            @include font(normal,normal,18px,#fff,30px);
            text-align: left;
            letter-spacing: -0.24px;
            &:hover,&.selected{
                @include transition(color .5s ease-in-out);
                color: #00CAFF;
            }
        }
    }
}

.mob__menuBtn{
    display: none;
    position: relative;
    width: 32px;
    height: 32px;
    margin-left: 20px;
    @include transform(rotate(0deg));
    @include transition(.5s ease-in-out);
    cursor: pointer;
    &--item{
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        background: #fff;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        @include transform(rotate(0deg));
        @include transition(.25s ease-in-out);
        &.item01{top: 5px;}
        &.item02,&.item03{top: 15px;}
        &.item04{top: 25px;}
    }
    &.open{
        span{
            &:nth-child(1){
                top: 18px;
                left: 50%;
                width: 0%;
            }
            &:nth-child(2){
                @include transform(rotate(45deg));
            }
            &:nth-child(3){
                @include transform(rotate(-45deg));
            }
            &:nth-child(4){
                top: 18px;
                left: 50%;
                width: 0%;
            }
        }
    }
}

/* media stylings =================================== */
@include breakPoint(1000px){
  .mob-hide{
    display: none;
  }
  .home__header{
    height: 85px;

    .container{
      height: 85px;  
      .header--container__leftWrap{
          gap: 10px;
        }

      .menu__btn__box{
        &--snsWrap{
          li{
            img{
              width: 21px;
            }
          }
        }
      }
    }
  }
}

@include breakPoint(860px){
  .launchApp__link{width: 125px; height: 45px;}
  .home__header{
    .container{padding: 32px 30px;}
    .home__nav{display: none;}
    .mob__menuBtn{display: block;}
    &.active{
      .container{
        position: fixed; top: 0;
        height: 118px;
        z-index: 30;
      }
      .mob__menu__box{
        display: block;
      }
    }
  }
  .mob__menu--itme--small{
    font-size: 12px;
    opacity: 0.8;
  }
  .menu__btn__box--snsWrap{
    gap: 12px;
  }
  .menu__btn__box--walletBtn{
    display: none;
  }
  .menu__btn__box--walletBtn-onMob{
    display: inline-block;
  }
}

@include breakPoint(600px){
  .header--container{
    &__leftWrap{
      .header__logo__link{
        img{
          height: 18px !important;
        }
      }
    }

    .mob__menuBtn{
      width: 26px !important;
      height: 26px !important;
      &--item{
        &.item02,&.item03{top: 12px;}
        &.item04{top: 19px;}
    }
    }
  } 
}
  