@charset "utf-8"; 

/* *******************************************************
    filename : popupBtn.css
    description : popupBtn layout CSS
******************************************************** */

// import
@import '../../media//css/mixin.scss';

/* ==================== popup stylings ====================== */

.popup-main{
  display: inline-block;
  margin-left: 5px;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  font-size: 14px;
  display: inline-flex;
  color: #A3A3AF;
  align-items: center;
  justify-content: center;
  transition-duration: 0.3s;
  background-color: #39393F !important;
  opacity: 0.8;
  cursor: pointer;
  &:hover{
    opacity: 1;
  }

  &__contents{
    font-size: 11px;
  }
}

.dialog-title-wrap{
  @include dspCollection(flex, space-between, center);
  background-color: #19222B;
  color: white;
  border-bottom: 1px solid lightgray;

  .close-Ico{
    width: 20px;
    height: 20px;
    opacity: 0.7;
    transition: all;
    cursor: pointer;

    &:hover{
      opacity: 1;
    }
  }
}

.dialog-contents-wrap{
  background-color: #19222B;
  color: white;
  height: 100%;
  padding: 10px 15px 15px 15px;
  font-size: 12px;
}

@include breakPoint(680px){
  .popup-main{
    &__contetns{
      font-size: 10px !important;
    }
  }
}