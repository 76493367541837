@charset "utf-8"; 

/* *******************************************************
    filename : macroData.css
    description : macroData layout CSS
******************************************************** */

// import
@import '../../media/css/mixin.scss';

/* ==================== variable stylings ====================== */
.mob-br{
  display: none;
}
.onMob-hide{
  display: show;
}
.selected{
  background: linear-gradient(55.98deg, #6A61DB -0.6%, #10CAFF 100.36%);
  color: #000 !important;
  font-weight: 700 !important;
}

/* ==================== macroData Root stylings ====================== */
.macroData__area{
  max-width: 1130px;
  height: 100%;
  padding-top: 156px;
  padding-bottom: 56px;
  margin: auto;
  font-family: var(--font0);
  
  // DefiEconomic stylings
  .economic-section{
    @include dspCollection(flex, space-between, center);
    flex-direction: column;
    width: 100%;
    margin-bottom: 78px;
    gap: 32px;
    
    &__title{
      @include font(normal, 700, 24px, #fff, 29px);
      width: 100%;
      text-align: left;
    }

    &__dataWrap{
      @include dspCollection(flex, space-between, center);
      width: 100%;
      
      &--dataBox{
        @include dspCollection(flex, center, flex-start);
        width: 100%;
        max-width: 260px;
        flex-direction: column;
        background-color: #131315;
        border-radius: 8px;
        padding: 20px 20px;

        &__left{
          margin-bottom: 10px;
          
          h1{
            @include font(normal, 400, 18px, #fff, 21.78px);
            .pointBoldText{
              @include font(normal, 700, 18px, #fff, 21.78px);
            }
          }
          span{
            @include font(normal, 400, 12px, #999, 16.94px);
          }
        }
        h3{
          span:first-child{
            @include font(normal, 400, 24px, #999, 29.05px);
            margin-right: 5px;
          }
          @include font(normal, 700, 24px, $positiveGreenColor, 29.05px);
          span{
            @include font(normal, 400, 24px, $positiveGreenColor, 29.05px);
            opacity: 0.6;
          }
        }
      }
    }
  }

  // Top yield pools by TVL stylings
  .topYield-section{
    width: 100%;

    &__title{
      @include dspCollection(flex, space-between, center);
      gap: 20px;
      margin-bottom: 27px;

      &--textWrap{
        h1{
          @include font(normal, 700, 24px, #fff, 29px);
        }
        p{
          @include font(normal, 400, 14px, #999, 21.78px);
          margin-top: 6px;
        }
      }

      ul{
        @include dspCollection(flex, center, center);
        gap: 10px;
        
        li{
          @include font(normal, 400, 18px, #fff, 21.78px);
          @include dspCollection(flex, center, center);
          width: 114px;
          height: 30px;
          border: 1px solid #505050;
          border-radius: 8px;
          transition-duration: 0.3s;
          cursor: pointer;
          user-select: none;
        }
      }
    }

    .tableWrap{
      max-width: 100%;
      margin: 0 auto;
    }
  }
}

  // table stylings
  .ag-header-cell-label {
    justify-content: center; 
  }

  [col-id=Pool] .ag-header-cell-text {
    width: 100%; 
    text-align: left; 
  }
  [col-id=Project] .ag-header-cell-text {
    width: 100%; 
    text-align: left !important;
  }
  [col-id=apyMean30d] .ag-header-cell-text {
    width: 100%;
    text-align: center;
    font-size: 14px !important;
    
  }

  .ag-header-cell-text{
    color: #757575 !important;
    font-family: var(--font0) !important;
    font-size: 16px !important;
    line-height: 19.36px !important;
  }

  .ag-row{
    font-family: var(--font0) !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 19.36px !important;
  }

  .ag-theme-alpine, .ag-theme-alpine-dark {
    --ag-row-hover-color: #000 !important;
  }

  .ag-theme-alpine-dark {
    --ag-background-color: transparent !important;
    --ag-border-color: transparent !important;
    --ag-secondary-border-color: transparent !important;
    --ag-header-background-color: transparent !important;
    --ag-odd-row-background-color: transparent !important;
  }

  .ag-root-wrapper{
    border: 1px solid #505050 !important;
    border-radius: 8px !important;
  }
  .macroData__imgCell{
    @include dspCollection(flex, flex-start, center);

    img{
      border-radius: 50%;
      margin-right: 10px;
    }
  }
  .macroData__imgCell--onlyImg{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
    img{
      border-radius: 50%;
    }
  }
  .macroData__alignCell{
    display: flex;
    justify-content: center;
  }


  @include breakPoint(1080px){
    .onMob-hide{
      display: none;
    }

    .macroData__area{
      padding: 0px 16px;
      padding-top: 105px;
      padding-bottom: 64px;
      
      .economic-section{
        gap: 24px;
        margin-bottom: 50px;
        
        &__title{
          font-size: 20px;
          line-height: 24.2px;
        }
        
        &__dataWrap{
          height: auto;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 10px;
          place-items: center;
          

          &--dataBox{
            max-width: 100%;
            padding: 10px 10px !important;
            -webkit-padding-before: 10px;
            -webkit-padding-end: 10px;
            -webkit-padding-after: 10px;
            -webkit-padding-start: 10px;

            &__left{
              margin-bottom: 0px;

              span{
                font-size: 12px;
              }
            }

            h3{
              span:first-child{
                font-size: 18px;
              }
              margin-top: 10px;
            }
          }
        }
      }

      .topYield-section{
        &__title{
          flex-direction: column;
          align-items: flex-start;

          h1{
            font-size: 20px;
            line-height: 24.2px;
          }
        }
      }
    }
  }

  @include breakPoint(600px){
    [col-id=Project].ag-header-cell {
      padding-left: 0px !important;
    }
    [col-id=Chain].ag-header-cell {
      padding: 0px !important;
    }

    .ag-cell{
      padding: 0px !important;
    }

    .macroData__imgCell{
      height: 100%;
      display: flex;
      align-items: center;
      gap: 10px;
      img{
        width: 25px;
        height: 25px;
        margin: 0 auto;
        margin-left: 10px;
      }
    }

    .topYield-section{

      &__title{

        ul{
          width: 100%;
          justify-content: space-between;

          li{
            width: max-content;
            font-size: 14px !important;
          }
        }
      }
    }
  }