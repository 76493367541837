@charset "utf-8"; 

/* *******************************************************
    filename : generator.css
    description : generator layout CSS
******************************************************** */

// import
@import '../../media/css/mixin.scss';

/* ==================== variable stylings ====================== */
.mob-br{
  display: none;
}

.onmob-hide{
  display: block;
}

.onmob-show{
  display: none;
}

.horizontal-line{
  width: 100%;
  border: 1px dashed #fff;
  opacity: 0.2;
  margin: 32px 0px;
}

.vertical-line{
  width: 1px;
  height: 60px;
  border:1px solid #303030;
  margin: 0px 40px;
}

.positive-color{
  color: $positiveGreenColor !important;
}
.negative-color{
  color:#c84a31 !important;
}
.forestGreen{
  color: #049F00 !important;
}
.grayText{
  color: #999 !important
}
.ml-1{
  margin-left: 5px;
}
.pointer{
  cursor: pointer;
}


/* ==================== generator Root stylings ====================== */
.generator__area{
  max-width: 800px;
  height: 100%;
  padding-top: 156px;
  padding-bottom: 56px;
  margin: auto;
  font-family: var(--font0);
}

/* ==================== generator Items stylings ====================== */
.generator__area--section{
  @include dspCollection(flex, space-between, center);
  flex-direction: column;
  gap: 15px;
  width: 100%;
  height: 100%;
  padding: 32px 48px;
  border-radius: 10px;
  margin-bottom: 32px;
  background-color: $masterContainerColor;
  position: relative;


  /* ==================== poolsummary stylings ====================== */
  .poolsummary-section{
    width: 100%;

    &__wrap{
      @include dspCollection(flex, space-between, center);
      gap: 15px;

      &--left{
        @include dspCollection(flex, flex-start, center);
        width: 55%;
        gap: 8px;
        position: relative;
        
        h1{
          @include font(normal, 700, 28px, #c8d5e6, 24px);
        }
      }
      

      &--right{
        @include dspCollection(flex, flex-end, center);
        width: 100%;
        
        .tvlAPYBox{
          @include dspCollection(flex, center, center);
          @include font(normal, bold, 24px, #fff, "");
          gap: 10px;
          font-weight: bold;
          display: flex;
          margin-right: 8px;
        
          &_inner{
            display: flex;
          }
          .realText{
            @include font(normal, 400, 20px, #fff, "");
            vertical-align: text-top;
            padding-top: 5px;
          }
          .apyText{
            @include font(normal, 400, 46px, #c8d5e6, "");
          }
        }
        .apyNumber{
          font-size: 50px;
          font-family: 'Roboto', sans-serif !important;
          font-weight: bold;
        }

        .descPoint{
          color: #c8d5e6;
          opacity: 0.6;
          font-family: 'Roboto', sans-serif !important;
        }
      }

      &--bottom{
        @include dspCollection(flex, flex-start, center);
        gap: 8px;
        img{
          width: 26px;
        }
        img:nth-child(2){
          filter: grayscale(1);
          opacity: 0.5
        }
        &--logoBox{
          @include dspCollection(flex, center, center);
          background-color: #39393F;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          opacity: 0.6;
          svg{
            width: 20px;
          }
        }
      }
    }
  }

  /* ==================== positionstats stylings ====================== */
  .positionstats-section{
    width: 100%;

    &__wrap{
      @include dspCollection(flex, space-between, center);

      &--dataBox{
        @include dspCollection(flex, space-between, center);
        flex-direction: column;
        width: 100%;
        vertical-align: auto;
        gap: 5px;

        .data-column{
          @include dspCollection(flex, space-between, center);
          width: 100%;
          h3{
            @include font(normal, 400, 18px, #fff, 21.78px);
            @include dspCollection(flex, flex-start, center);
            width: 100%;
            
            .timesAgo{
              @include font(normal, 400, 12px, #999, 17px);
              margin-left: 10px;
              white-space: nowrap;
            }
          }
          .subText{
            @include font(normal, 400, 12px, #999, 17px);
            margin-left: 5px;
          }

          &--valueWrap{
            @include font(normal, 400, 20px, #c8d5e6, 29.05px);
            @include dspCollection(flex, flex-end, center);
            font-family: 'Roboto', sans-serif !important;
            gap: 20px;
            width: 100%;
           
           &__amount{
            @include dspCollection(flex, flex-end, center);
            min-width: 130px;
            text-align: right;
           }

            &__percent{
              @include dspCollection(flex, flex-end, center);
              min-width: 80px;
              span{
                @include font(normal, 400, 16px, #fff, 29.05px);
                width: max-content;
                height: 100%;
                text-align: center;
                background-color: #39393F;
                border-radius: 4px;
                padding: 0px 6px;
              }
            }
          }
        }
      }
    }
  }
}


/* ==================== media stylings ====================== */
@include breakPoint(1080px) {

  .onmob-hide{
    display: none;
  }
  .onmob-show{
    display: block;
  }
  .horizontal-line{
    margin: 24px 0px;
  }

  .generator__area{
    padding-top: 105px;
    padding-bottom: 24px;

    &--section{
      padding: 24px 16px; 
    }
  }
  
  .generator__area--section{
    
    .poolsummary-section{
      
      &__wrap{
        margin-bottom: 10px;

        &--left{
          width: 100%;

          &--img{
            img{
              width: 24px;
              margin-right: 22px;
            }
            img:last-child{
              left: 21px
            }
          }
          h1{
            font-size: 18px;
            line-height: 21px;
          }
        }

        &--right{
          .tvlAPYBox{
            
            .realText{
              font-size: 13px;
              padding-top: 0px;
            }
            .apyText{
              font-size: 30px;
            }
          }
          .apyNumber{
            font-size: 30px;
          }
        }
      }
    }

    .positionstats-section{
      
      &__wrap{
        width: 100%;
        flex-direction: column;

        &--dataBox{
          gap: 0px;

          .data-column{
            h3{
              @include font(normal, 400, 16px, #fff, 21.78px);
            }
            &--valueWrap{
              @include font(normal, 400, 16px, #c8d5e6, 29.05px);
              gap: 20px;
              width: unset;
             
             &__amount{
              min-width: 85px;
             }
  
              &__percent{
                min-width: 60px;
                span{
                  @include font(normal, 400, 12px, #fff, 29.05px);
                  padding: 0px 6px;
                }
              }
            }
          }
        }
      }
    }
  }

} /* 1080px END */


@include breakPoint(600px) {
    .generator__area--section{
      gap: 10px;

      .poolsummary-section{
        
        &__wrap{

          &--left{
            width: 100%;
            max-width: 160px;
            gap: 6px;

            &--img{
              min-width: 48px;
            }
            &--fee{
              font-size: 12px;
              width: 45px;

            }
          }

          &--right{
            height: 23px;
            .tvlAPYBox{
              font-size: 22px;
              white-space: nowrap;

              .realText{
                font-size: 13px;
              }
              .apyText{
                font-size: 24px;
              }
            }
            .apyNumber{
              white-space: nowrap;
              font-size: 28px;
            }
          }
        }
      }

      .positionstats-section{
        &__wrap{
          &--dataBox{
            .data-column{
              h3{
                font-size: 16px;
              }
              .subText{
                font-size: 10px;
              }
              &--valueWrap{
                gap: 15px;
             
                &__amount{
                min-width: 85px;
               }
    
                &__percent{
                  min-width: 60px;
                  span{
                    @include font(normal, 400, 12px, #fff, 24.05px);
                    padding: 0px 6px;
                  }
                }
              }
            }
          }
        }
      }
  }
} /* 600px END */


@include breakPoint(340px){
  .mob-br{
    display: block;
  }
}