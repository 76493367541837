@charset "utf-8"; 

/* *******************************************************
    filename : analytics.css
    description : analytics layout CSS
******************************************************** */

// import
@import '../../media/css/mixin.scss';

/* ==================== variable stylings ====================== */
.mob-br{
  display: none;
}

.onmob-flex-show{
  display: none !important;
}
.onmob-flex-hide{
  display: flex !important;
}

.MuiAccordionSummary-root{
  user-select: none;
}
.MuiAccordionSummary-expandIconWrapper.Mui-expanded{
  transform: rotate(45deg) !important;
}

.chart-loading-wrap{
  position: absolute;
  bottom: 40%;
}

/* ==================== analytics stylings ====================== */
.analytics__area{
  max-width: 1080px;
  height: 100%;
  padding-top: 156px;
  padding-bottom: 56px;
  margin: auto;
  font-family: var(--font0);

  &--pageTitle{
    @include font(normal, 700, 24px, #fff, 29px);
  }
}

/* ==================== positionstats stylings ====================== */

.analytics__area--section{
  @include dspCollection(flex, space-between, center);
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  margin: 32px 0px;
  background-color: $masterContainerColor;


  // AnalyticsSummary stylings
  .pooldeviation-section{
    width: 100%;
    color: white;
    background-color: $masterContainerColor;
    padding-bottom: 10px;

    &__header{
      width: 100%;
      @include dspCollection(flex, space-between, center);
      padding: 36px 48px 0px 48px;
    }

    &__titleWrap{
      @include dspCollection(flex, flex-start, center);
      width: 100%;
      gap: 16px;
    }

    &__title{
      @include dspCollection(flex, flex-start, center);
      @include font(normal, 500, 20px, #fff, 24px);
      gap: 16px;
      
      &--img{
        @include dspCollection(flex, center, center);
        width: max-content;
        gap: 10px;
        img{
          width: 28px;
          vertical-align: bottom;
        }
        img:nth-child(2){
          filter: grayscale(1);
        }
        .logoBox{
          @include dspCollection(flex, center, center);
          background-color: #39393F;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          svg{
            width: 20px;
          }
        }
      }
    }

    .chartBtns{
      @include dspCollection(flex, space-around, center);
      max-width: 300px;
      width: 100%;
      gap: 10px;
      background-color: #1F2128;
      padding: 5px 10px;
      border-radius: 10px;
      
      button{
        @include font(normal, 400, 14px, #757575, 17px);
        transition-duration: 0.3s;
      }

      .clicked{
        color: white;
      }
    }

    &--top{
      @include dspCollection(flex, center, center);
      gap: 40px;
      width: 100%;
      position: relative;
    }

    &__contents{
      @include dspCollection(flex, center, center);
      flex-direction: column;
      gap: 5px;

      &--dataWrap{
        width: 380px;
        height: 400px;
        border-radius: 10px;
        position: absolute;
        top: 0;
        left: 48px;
        z-index: 3;

        &--inner{
          padding: 32px 0px;
          
          &__data{
            @include dspCollection(flex, space-between, center);
            padding: 20px 0px;
            border-bottom: 1px dashed rgba(255,255,255,0.1);
            h1{
              @include font(normal, 400, 18px, #bbbbbb, 22px);
            }
            h3{
              @include font(normal, 400, 18px, #fff, 22px);
            }
          }
          &__data:first-child{
            padding-top: 0px
          }
          &__data:nth-child(4){
            .volatility--inner{
              @include dspCollection(flex, center, center);
              gap: 16px;

              .period--btns{
                width: fit-content;
                @include dspCollection(flex, center, center);
                gap: 8px;
                button{
                  width: fit-content;
                  @include font(normal, 400, 13px, #757575, 16px);
                  transition-duration: 0.3s;
                }
                .period-selected{
                  text-decoration: underline;
                  font-weight: 700;
                  color: white;
                }
              }
            }
          }
          &__data:last-child{
            padding-bottom: 0px;
            border-bottom: none;
          }
        }
      }

      &--chartWrap{
        @include dspCollection(flex, space-between, center);
        flex-direction: column;
        width: 100%;
        height: 500px;
        border-radius: 10px;
        position: relative;

        &--title{
          @include dspCollection(flex, center, center);
          flex-direction: column;
          width: 100%;
          padding: 28px 12px 10px 25px;

          &--top{
            @include dspCollection(flex, space-between, center);
            width: 100%;

            &__price{
              span:first-child{
                @include font(normal, 400, 14px, #757575, 17px);
                margin-right: 12px;
              }
              span:last-child{
                @include font(normal, 700, 18px, #fff, 21px);
              }
            }
          }
          &--bottom{
            width: 100%;
            span:first-child{
              @include font(normal, 400, 13px, #757575, 16px);
              margin-right: 10px
            }

            .currentPrice-Wrapper{
              h1{
                @include font(normal, 400, 13px, #757575, 16px);

                @include dspCollection(flex, flex-start, center);
                gap: 7px;
                div{
                  width: 7px;
                  height: 7px;
                  border-radius: 50%;
                  background-color: #FF007A;
                }
              }

              &__price{
                @include font(normal, 400, 13px, #fff, 18px);
              }
            }
          }
        }

        &--tooltipWrapper{
          @include dspCollection(flex, center, flex-start);
          flex-direction: column;
          gap: 5px;
          background-color: $masterBgColor;
          padding: 12px;
          width: 100%;
          opacity: 0.7;
          font-size: 12px;
          border-radius: 10px;
          z-index: 10;

          h1{
            font-size: 12px;
            opacity: 0.7;
          }
          &__dataLine{
            min-width: 180px;
            @include dspCollection(flex, space-between, center);
          }
        }

        &--controlsWrapper{
          position: absolute;
          right: 20px;
          bottom: 60px;
          padding: 4px;
          border-radius: 8px;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 6px;

          .zoomBtn{
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: #666;
            display: flex;
            justify-content: center;
            font-size: 18px;
            font-weight: bold;
            line-height: 20px;
            align-items: center;
            user-select: none;
          }
          .zoomBtn:hover {
            cursor: pointer;
            opacity: 0.4;
          }
        }
      }
    }
  }
}

.chart-tooltip{
  @include dspCollection(flex, center, flex-start);
  @include font(normal, 400, 11px, #fff, 15px );
  flex-direction: column;
  padding: 5px;
}


/* ==================== media stylings ====================== */
@include breakPoint(1080px) {
  .onmob-flex-show{
    display: flex !important;
  }
  .onmob-flex-hide{
    display: none !important;
  }

  .analytics__area{
    padding-top: 105px;
    padding-bottom: 24px;
  
    &--pageTitle{
      padding-left: 16px;
      font-size: 20px;
      line-height: 24px;
    }
  }

  .analytics__area--section{
    border-top: 1px solid #303030;
    border-bottom: 1px solid #303030;
    padding: 0px 16px;
    margin: 20px 0px;

    .pooldeviation-section{
      
      &__header{
        padding: 20px 20px 0px 20px;
      }

      &__title{
        padding: 0px;

        &--img{
          
          img{
            width: 24px;
          }
        }
      }

      &--top{
        flex-direction: column;
        width: 100%;
        gap: 20px;
      }

      &__contents{
        padding: 0px;

        &--dataWrap{
          width: 100%;
          position: unset;
          height: 100%;
          

          &--inner{
            padding: 26px 20px;
          
            &__data{
              h1{
                font-size: 16px;
                line-height: 19px;
              }
            }
          }
        }

        &--chartWrap{
          width: 100%;
          height: 400px;

          &--title{
            @include dspCollection(flex, space-between, center);
            padding: 20px;

            &__price{
              span:first-child{
                color: #fff;
              }
              span:last-child{
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
} /* 1080px END */

@include breakPoint(600px) {
  .analytics__area--section{
    .pooldeviation-section{
      &__title{
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
      }

      &__contents{
        &--chartWrap{
          &--title{
            &--top{
              flex-direction: column-reverse;
              align-items: flex-start;
              gap: 10px;

              &__btns{
                max-width: unset;
              }
            }
          }
          &--controlsWrapper{
            bottom: 20px;
          }
        }
      }
    }
  }
}


@keyframes rotateIcon {
  from{
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  }
}

.refetch-section{
  width: 100%;
  gap: 5px;
  padding-right: 10px;

  &--period-column{
    @include dspCollection(flex, flex-end, center);
    width: 100%;
  }
  
  &--link{
    @include font(normal, 400, 12px, #999, 17px);
  }

  &--period{
    @include font(normal, 400, 12px, #999, 17px);
    margin-left: 190px;
  }

  &--refetchBtn{
    @include font(normal, 400, 12px, #999, 17px);
    cursor: pointer;
    
    img{
      width: 14px;
      margin-left: 5px;
    }
  }
  &--refetchBtn:hover{
    img{
      animation: rotateIcon 1s ease-in;
    }
  }
}