@charset "utf-8"; 

/* *******************************************************
    filename : footer.css
    description : footer layout CSS
******************************************************** */

// import
@import '../../media/css/mixin.scss';

/* ==================== footer stylings ====================== */
#footer{
    width: 100%;
    background-color: #1B1B1B;
    .footer__inner{max-width: 1140px;margin: auto;}
    .foot__top__area{
        @include dspCollection(flex,space-between,flex-start);
        padding: 65px 0;
        @include boxsizing;
        .logo{padding-bottom: 24px;}
        .foot__top--txt{
            padding-bottom: 32px;
            font-family: var(--font1);
            @include font(normal,normal,13px,#fff,19px);
            letter-spacing: -0.24px;
        }
        .link__list__box{
            display: flex;
            gap: 50px;
            margin-right: 100px;
            &--mob{display: none}
        }
        
    }
    .foot__bom__area{
        @include dspCollection(flex,space-between,center);
        padding: 22px 0;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        .link__list{
            @include flexCenter;
            gap: 32px;
            &--txt{
                &:hover{
                    color: #fff;
                    @include transition(color .3s ease-in-out);
                }
            }
        }
        .copyright{
            font-family: var(--font1);
            @include font(normal,normal,13px,#85878F,18px);
            letter-spacing: -0.08px;
        }
        
    }
    .link__list{
        &--tit{
            font-family: var(--font2);
            @include font(normal,bold,15px,#00F1FF,24px);
            letter-spacing: -0.41px;
        }
        &--link{
            display: block;
            padding-top: 16px;
            font-family: var(--font1);
            @include font(normal,normal,15px,#fff,24px);
            text-align: left;
            letter-spacing: -0.24px;
            &:hover{
                mask-image: linear-gradient(-75deg, rgba(0,0,0,.6) 30%, #000 50%, rgba(0,0,0,.6) 70%);
                mask-size: 200%;
                -webkit-mask-image: linear-gradient(-75deg, rgba(0,0,0,.6) 30%, #000 50%, rgba(0,0,0,.6) 70%);
                -webkit-mask-size: 200%;
                @include animation(shine 2s infinite)
            }
        }
        &--txt{
            font-family: var(--font1);
            @include font(normal,normal,13px,#85878F,18px);
            letter-spacing: -0.08px;
        }
        &--hidden-link{
            font-family: var(--font1);
            @include font(normal,normal,13px,#85878F,18px);
            letter-spacing: -0.08px;
            user-select: none;
        }
    }
    .foot__sns__link {
        .sns__link + .sns__link {margin-left: 26px;}
        .sns__link{
            display: inline-block;
            width: 20px;
            &:hover{
                @include animation(shake 0.6s infinite alternate)
            }
        }
    }
}

@include keyframes(shake){
    0% {transform: rotateZ(10deg);transform: rotateZ(10deg);}
    100% {transform: rotateZ(-10deg);transform: rotateZ(-10deg);}
}

@include keyframes(shine){
    0% {-webkit-mask-position: 150%;}
    100% {-webkit-mask-position: -50%;}
}

/* media stylings =================================== */
@include breakPoint(1000px) {
    .footer__inner{
        max-width: 100%!important;
        padding: 0 30px;
        @include boxsizing;
    }
    #footer{
        .foot__top__area{
            justify-content: center;
            padding: 20px 0;
            text-align: center;
            
            .logo{
              padding-right: 3px;
              padding-bottom: 10px;
            }
            .foot__top--txt{
              padding-bottom: 0;
            }

            .link__list__box{
                &--web{display: none}
                &--mob{
                    display: block;
                    padding-left: 75px;
                    .link__list--item + .link__list--item{padding-top: 8px;}
                    .link__list--link{padding-top:0;}
                }
            }
        }
        .foot__bom__area{
            display: block;
            .link__list{gap: 15px;padding-bottom: 16px;}
        }
    }
}

@include breakPoint(490px) {
    #footer .foot__top__area .link__list__box--mob{padding-left: 18px;}
    
  }