@charset "utf-8"; 

@use "sass:color";

// -*- primary-color
$masterBgColor: #19191C;
$masterContainerColor: #131315;
$masterColor: #2d3862;
$masterWiteColor: #fff;
$masterBlackColor: #000;
$masterGrayColor: #666;
$innerBoxColor: "#1F1F22";
$headerMobColor: #201F24;
$lightGrayColor: #999;
$darkGray: #333;
$borderColor: #eee;
$greenColor: #01AA68;
$redColor: #E92318;
$pointGreenColor: #369E49;
$positiveGreenColor: #68E454;
$whiteTransparent: rgba(250,250,250,0.5);
