@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&family=Work+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap');
@import url('https://fonts.cdnfonts.com/css/euclid-circular-b');


body{
  font-family: 'Space Mono', monospace;
}

:root {
  font-size: 10px;
  font-family: var(--font1);
  position: relative;
  background-color: var(--almost-black);

  /* COLORS */

  --fade-white: rgba(255, 255, 255, 0.5);
  --x-fade-white: rgba(255, 255, 255, 0.1);
  --almost-white: #dad9d8;
  --white: white;

  --grey: #3a3939;
  --lightgrey: #606060;
  --almost-black: #1B1B1B; /*#121212; */ /*#22262C*/
  --dark-gradient-bg: linear-gradient(210.55deg, #292D32 -3.02%, #1F2123 93.08%);
  --dark-gradient-bg2: linear-gradient(180deg,#353a40,#16171b);

  --fade-black: rgba(0, 0, 0, 0.5);
  --dark-grey: #272B30;
  --light-black: #202024;
  --black-overlay: rgba(0, 0, 0, 0.678);
  --black: black;
  --green: #01AA68;
  --green2: #369E49;
  --green-dark: rgb(29, 142, 72, 0.3);
  --green-dark-full: rgb(25, 147, 69, 1);
  --green-gradient-bg3: linear-gradient(320.66deg,#369e49 14.75%,#48dc63 84.81%),linear-gradient(320.66deg,#01aa68 14.75%,#00d381 84.81%);
  --green-gradient-bg2: linear-gradient(320.66deg, #01AA68 14.75%, #00D381 84.81%);
  --green-gradient-bg: linear-gradient(320.66deg, #369E49 14.75%, #48DC63 84.81%);
  

  --skyBlue: #17a2b1;
  --red: #9E3636;
  --deep-red: #EF0808;
  --error: #dd6579;
  --warning: #faa613;
  --slider-track-gradient: linear-gradient(
    90deg,
    var(--almost-white) 0%,
    var(--green-dark-full) 50%,
    var(--green-dark-full) 100%
  );
  
  /* FONTS */
  /*  system-ui, -apple-system, BlinkMacSystemFont, 'Open Sans', sans-serif; */
  --font0: 'Inter', sans-serif;
  --font1: "Work Sans", "Nunito", sans-serif;
  --font2: "Space Mono", "Nunito";  
  --font3: "DS-Digital", "LED Board-7", "Work Sans";

  /* BREAKPOINTS */
  --tabletBreakpoint: 1000px;
  --smallLaptopBreakpoint: 1441px;
  --largeScreenBreakpoint: 1919px;

  /* TRANSITIONS */

  --transition: all 200ms ease-in-out;
  --Ytransform: translateY(-50%);
  --transition2: transform 1s ease-in-out;
}